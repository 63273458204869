import React, { useEffect } from 'react'
import { UsuarioGetAcessos } from '../../Service/ApiService'
import { putUserAccessMenu } from '../../Reducers/storageApp'
import { Biz_IsAccessCodigo } from '../../Service/BizService'

export default function Element({ onChange }) {
  async function User_LoadMenu() {
    const acessos = await UsuarioGetAcessos()

    let menu = []
    if (acessos) {
      if (Biz_IsAccessCodigo('Dashboard01') === true) menu.push({ icon: 'fa fa-chart-pie', label: 'Dashboard', to: '/#/Home' })
      else menu.push({ icon: 'fa fa-list', label: 'Home', to: '/#/' })

      acessos.forEach((itemG) => {
        if (itemG.grupo === 'Programação') {
          menu.push({ icon: 'fa fa-calendar', label: 'Programação', to: '/#/Program' })
        } else if (itemG.grupo === 'Praça') {
          menu.push({ icon: 'fa fa-signal', label: 'Praças', to: '/#/Square' })
        } else if (itemG.grupo === 'SysMonitor') {
          if (itemG.itens.filter((f) => f.codigo === 'SysMonitor').length > 0) menu.push({ icon: 'fa fa-desktop', label: 'Monitor', to: '/#/SysMonitor' })
          if (itemG.itens.filter((f) => f.codigo === 'SysNoc').length > 0) menu.push({ icon: 'fa fa-exclamation-triangle', label: 'NOC', to: '/#/SysNoc' })
        } else if (itemG.grupo === 'Postagem') {
          menu.push({ icon: 'fa fa-rss-square', label: 'Postagens', to: '/#/Post' })
        } else if (itemG.grupo === 'Configurações') {
          let menuGrupo = []
          itemG.itens.forEach((itemM) => {
            if (itemM.codigo === 'ParametroCon') {
              menuGrupo.push({ label: 'Parâmetros', to: '/#/Settings/Parameters' })
            } else if (itemM.codigo === 'PerfilCon') {
              menuGrupo.push({ label: 'Perfis', to: '/#/Settings/Profile' })
            } else if (itemM.codigo === 'UsuarioCon') {
              menuGrupo.push({ label: 'Usuários', to: '/#/Settings/User' })
            } else if (itemM.codigo === 'BannerCon') {
              menuGrupo.push({ label: 'Banners', to: '/#/Settings/Banner' })
            }
          })

          if (menuGrupo.length > 0) {
            menu.push({
              icon: 'fa fa-gear',
              label: 'Configurações',
              content: menuGrupo,
            })
          }
        }
      })
    }
    await putUserAccessMenu(acessos, menu)
    onChange()
  }

  useEffect(() => {
    User_LoadMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}
