import { useEffect, useState } from 'react'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import { Button, Card, CardBody, Col, Collapse, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { Header } from './styles'
import { Select, toast, Bounce, moment } from '../../../../Components'
import { PracaGetByFilter } from '../../../../Service/ApiService'
import { IoMdRefresh } from 'react-icons/io'

export default function CardFilter({ change }) {
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [pracas, setPracas] = useState([])
  const [values, setValues] = useState()

  async function loadPraca() {
    const response = await PracaGetByFilter({ situacao: 'Ativo' })
    if (response) {
      setPracas(response.map((praca) => ({ label: praca.nome, value: praca.pracaId })))
    } else {
      toast('Ops, ocorreu um problema ao carregar as praças', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
  }

  useEffect(() => {
    let _dtAtual = new Date()
    _dtAtual.setMinutes(_dtAtual.getMinutes() - _dtAtual.getTimezoneOffset())

    let _dtInicio = new Date(_dtAtual)
    _dtInicio.setDate(-6)

    const filters = { pracaId: null, selectedFilter: 'last7Days', dtInicio: _dtInicio.toISOString().substr(0, 10), dtTermino: _dtAtual.toISOString().substr(0, 10) }

    setValues(filters)
    onBuscar(filters)
    loadPraca()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChange(ev) {
    const { name, value } = ev.target
    let selectedFilter = ['dtInicio', 'dtTermino'].includes(name) ? 'custom' : value.selectedFilter
    setValues({ ...values, [name]: value, selectedFilter })
  }
  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    setValues({ ...values, [name]: newValue })

    onBuscar({ ...values, [name]: newValue })
  }

  function filtroMesSemana(type) {
    let _dtAtual = new Date()
    _dtAtual.setMinutes(_dtAtual.getMinutes() - _dtAtual.getTimezoneOffset())
    let _dtInicio
    switch (type) {
      case 'last7Days':
        _dtInicio = new Date(_dtAtual)
        _dtInicio.setDate(_dtInicio.getDate() - 7)
        break
      case 'last30Days':
        _dtInicio = new Date(_dtAtual)
        _dtInicio.setDate(_dtInicio.getDate() - 30)
        break
      case 'currentMonth':
        _dtInicio = new Date(_dtAtual.getFullYear(), _dtAtual.getMonth(), 1)
        break
      case 'previousMonth':
        _dtInicio = new Date(_dtAtual.getFullYear(), _dtAtual.getMonth() - 1, 1)
        _dtAtual = new Date(_dtAtual.getFullYear(), _dtAtual.getMonth(), 0)
        break
      case 'custom':
        _dtInicio = new Date(values.dtInicio)
        _dtAtual = new Date(values.dtTermino)
        break
      default:
        _dtInicio = new Date(values.dtInicio)
        _dtAtual = new Date(values.dtTermino)
        break
    }
    const newValues = { ...values, dtInicio: _dtInicio.toISOString().substr(0, 10), dtTermino: _dtAtual.toISOString().substr(0, 10), selectedFilter: type }
    setValues(newValues)
    onBuscar(newValues)
  }

  function onBuscar(filters) {
    let _filters = { ...filters }

    _filters.dtInicio = _filters.dtInicio ? _filters.dtInicio : null
    _filters.dtTermino = _filters.dtTermino ? _filters.dtTermino : null
    _filters.pracaId = _filters.pracaId?.value ? _filters.pracaId.value : null
    _filters.now = new Date()

    change(_filters)
    setIsOpenFilter(false)
  }

  return (
    <Card className="main-card mb-2">
      {values && (
        <>
          <Header>
            <span>Filtros</span>
            <div>
              <UncontrolledButtonDropdown className="mr-1">
                <DropdownToggle caret color="info">
                  {
                    {
                      last7Days: 'Últimos 7 dias',
                      last30Days: 'Últimos 30 dias',
                      currentMonth: 'Mês atual',
                      previousMonth: 'Mês anterior',
                      custom: `${values.dtInicio ? moment(values.dtInicio).format('DD/MM/YYYY') : ''} - ${values.dtTermino ? moment(values.dtTermino).format('DD/MM/YYYY') : ''}`,
                    }[values.selectedFilter]
                  }
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => filtroMesSemana('last7Days')}>Últimos 7 dias</DropdownItem>
                  <DropdownItem onClick={() => filtroMesSemana('last30Days')}>Últimos 30 dias</DropdownItem>
                  <DropdownItem onClick={() => filtroMesSemana('currentMonth')}>Mês atual</DropdownItem>
                  <DropdownItem onClick={() => filtroMesSemana('previousMonth')}>Mês anterior</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
              <Button onClick={() => onBuscar(values)} className="btn-pill" color="primary">
                <IoMdRefresh size={18} />
                <span className="ml-1">Buscar</span>
              </Button>
              <Button onClick={() => setIsOpenFilter(!isOpenFilter)} className="ml-1 btn-pill" color="outline-primary" id="btCollapse">
                {isOpenFilter ? <MdExpandLess size={18} /> : <MdExpandMore size={18} />}
              </Button>
            </div>
          </Header>
          <Collapse isOpen={isOpenFilter}>
            <CardBody>
              <Row>
                <Col md={2}>
                  <FormGroup>
                    <Label>Data Início</Label>
                    <Input type="date" onChange={onChange} value={values.dtInicio} name="dtInicio" id="dtInicio" />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label>Data Término</Label>
                    <Input type="date" onChange={onChange} value={values.dtTermino} name="dtTermino" id="dtTermino" />
                  </FormGroup>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    <Label>Praça</Label>
                    <Select
                      placeholder="Selecione"
                      noOptionsMessage={() => 'Nenhuma opção disponível'}
                      onChange={onChangeCat}
                      value={values.pracaId}
                      isClearable
                      name="pracaId"
                      className="basic-select"
                      classNamePrefix="select"
                      options={pracas}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Collapse>
        </>
      )}
    </Card>
  )
}
